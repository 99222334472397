import React from "react";
import BlogFeed from "../components/blog-feed";
import CTA from "../components/cta";
import Layout from "../components/layout";
import Seo from "../components/seo"
import patternHex from "../images/backrounds/print-design.jpeg";
import {StaticImage} from "gatsby-plugin-image";
import NeedAQuote from "../components/need-a-quote";

const ourClients = [
  <StaticImage
    src="../images/clients/ultimate-RB.jpeg"
    alt="ultimate Rb"
    placeholder="blurred"
  />,
  <StaticImage
    src="../images/clients/hero.jpeg"
    alt="10x Genomics"
  />,
  <StaticImage
    src="../images/clients/shipnet.jpeg"
    alt="Shipnet"
  />,
  <StaticImage
    src="../images/clients/outdoor.png"
    alt="Outdoor"
  />,
  <StaticImage
    src="../images/clients/pigskin.jpeg"
    alt="Pigskin"
  />,
  <StaticImage
    src="../images/clients/gtventure.jpeg"
    alt="Gtventure"
  />,
  <StaticImage
    src="../images/clients/taleswing.jpeg"
    alt="taleswing"
  />,
  <StaticImage
    src="../images/clients/psychiatic-alternatives.png"
    alt="Psychiatic-alternatives"
  />
]

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Print Design"
        description="Our team includes a full stack design shop - which means your brand can be managed all under one roof. From business cards to website - we&#039;re here to help."
      />
      <section>
        <div
          className="hero"
          style={{
            backgroundImage: `url(${patternHex})`,
          }}
        >
          <div className="hero-title bg-primary-transparent">
            <h1 className="text-white">your one-stop-shop for all things print</h1>
            <p className="text-white">
              Whether it’s a 2-inch business card or a 60-foot billboard, a tri-fold brochure or a semi-truck wrap –
              729’s designers will tackle and complete your print project of any size or medium. We can apply your
              existing brand or create something entirely from scratch. We’ve worked with companies large and small to
              customize projects to their exact specifications and turn digital designs into print reality.
            </p>
            <CTA
              href="/contact-us/"
              text="Tell Us About Your Project"
              className="btn btn-secondary-light"
            />
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="row">
            <div className="col">
              <h2 className="mb-2">print projects we love</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-4 mt-4">
              <h3>Business Collateral</h3>
              <ul className="text-primary m-0 pl-3">
                <li><span className="text-dark">Business Cards</span></li>
                <li><span className="text-dark">Letterhead</span></li>
                <li><span className="text-dark">Annual Reports</span></li>
                <li><span className="text-dark">White Papers</span></li>
                <li><span className="text-dark">Data Sheets</span></li>
              </ul>
              <p>&nbsp;</p>
              <h3>Marketing Collateral</h3>
              <ul className="text-primary m-0 pl-3">
                <li><span className="text-dark">Brochures</span></li>
                <li><span className="text-dark">Catalog Design</span></li>
                <li><span className="text-dark">Stickers</span></li>
                <li><span className="text-dark">Posters &amp; Flyers</span></li>
                <li><span className="text-dark">Press Kits</span></li>
                <li><span className="text-dark">Billboards</span></li>
                <li><span className="text-dark">Postcards</span></li>
              </ul>
            </div>
            <div className="col-12 col-lg-4 mt-4">
              <h3>Event Design</h3>
              <ul className="text-primary m-0 pl-3">
                <li><span className="text-dark">Vinyl Banners</span></li>
                <li><span className="text-dark">Vehicle Wraps</span></li>
                <li><span className="text-dark">Floor Displays</span></li>
                <li><span className="text-dark">Wall &amp; Window Graphics</span></li>
                <li><span className="text-dark">Tradeshow Booths</span></li>
                <li><span className="text-dark">Event Booths</span></li>
                <li><span className="text-dark">Ticket Stubs</span></li>
                <li><span className="text-dark">Retractable Banners</span></li>
                <li><span className="text-dark">Event Tents</span></li>
                <li><span className="text-dark">Yard Signs</span></li>
              </ul>
            </div>
            <div className="col-12 col-lg-4 mt-4">
              <h3>Swag</h3>
              <ul className="text-primary m-0 pl-3">
                <li><span className="text-dark">Apparel (Shirts / Hats)</span></li>
                <li><span className="text-dark">Lanyards</span></li>
                <li><span className="text-dark">Pins</span></li>
                <li><span className="text-dark">Gift Bags / Totes</span></li>
                <li><span className="text-dark">Pens</span></li>
                <li><span className="text-dark">Mugs &amp; Cups</span></li>
              </ul>
              <p>&nbsp;</p>
              <h3>Other</h3>
              <ul className="text-primary m-0 pl-3">
                <li><span className="text-dark">Package Design</span></li>
                <li><span className="text-dark">POP Displays</span></li>
                <li><span className="text-dark">Logo &amp; Brand Design</span></li>
                <li><span className="text-dark">&amp; So Much More!</span></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>some of our design clients</h2>
            </div>
          </div>
          <div className="row">
            {ourClients.map((component) => (
              <div className="col-12 col-lg-3">
                <div className="row">
                  <div className="col-12 col-lg-9">
                    <div className="row">
                      <div className="col text-center mb-5">
                        {component}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <NeedAQuote
        buttonText="let's talk"
        title="we’re ready to help"
        text="729 Studio’s design team is ready to take on your project and ensure that it gets the attention it deserves. Let’s talk when you’re ready to be noticed."
      />
      <BlogFeed containerClassname="py-6"/>
    </Layout>
  );
};

export default Index;
